@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
body {
  margin: 0;
  font-family: 'Noto Serif TC',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.switchBtn.MuiButtonBase-root.MuiButton-root{
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.switchBtnLeft.MuiButtonBase-root.MuiButton-root{
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.MuiListItemIcon-root{
  vertical-align: sub;
  min-width: 35px!important;
}

.MuiPaper-root.MuiDrawer-paper{
  border-right: 1px dashed rgba(145, 158, 171, 0.24);
}

main.MuiBox-root{
  background-color: #fff;
}

.MuiGrid-root.MuiGrid-item{
  padding: 8px 0px;
}

.MuiGrid-root.MuiGrid-item.fix{
  padding: 16px 0px;
}

.MuiButtonBase-root.MuiListItemButton-root{
  margin: 0;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.MuiButtonBase-root.MuiListItemButton-root:hover{
  background-color:rgba(85, 193, 241,.1)
}
.MuiButtonBase-root.MuiListItemButton-root:hover span,
.MuiButtonBase-root.MuiListItemButton-root:hover .MuiListItemIcon-root{
  color: #0464B4;
  font-weight: 500;
}

.MuiButtonBase-root.MuiListItemButton-root.Mui-selected{
  background-color:rgba(85, 193, 241,.2)
}

.MuiButtonBase-root.MuiListItemButton-root.Mui-selected span,
.MuiButtonBase-root.MuiListItemButton-root.Mui-selected .MuiListItemIcon-root {
  color: #0464B4;
  font-weight: 600;
}

.MuiButtonBase-root.MuiListItemButton-root.menu_title{
  margin: 0px;
  background: linear-gradient(to right, #0464B4 ,  #0589D2, #55C1F1);
  color: #fff;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
.MuiButtonBase-root.MuiListItemButton-root.menu_title:hover span{
  color: #fff;
}

.MuiButtonBase-root.MuiListItemButton-root.menu_title span{
  font-weight: 700;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text{
  padding: 0 8px;
}
.MuiButtonBase-root.MuiButton-root{
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary,
.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary{
  box-shadow: rgba(5, 137, 210,.15) 0px 4px 8px 0px;
  height: 40px;
}

.MuiButton-root.MuiButton-outlined.MuiButton-outlinedError{
  box-shadow: rgba(225, 23, 68,.1) 0px 4px 8px 0px;
}

.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary:hover{
  box-shadow: none
}

.MuiButton-root.MuiButton-outlined.MuiButton-outlinedError:hover{
  box-shadow: none
}

.MuiPaper-root.MuiCard-root, .tablePaper.MuiPaper-root{
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.MuiCard-root .MuiCardContent-root{
  padding: 8px 32px;
}

.MuiCardContent-root .MuiCardActions-root{
  justify-content: flex-end;
  padding: 8px 0;
}

.MuiButtonBase-root.MuiButton-root.login_btn{
  font-family: 'Noto Sans TC';
  padding: 12px 16px;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.MuiInputBase-root.MuiOutlinedInput-root{
  font-family: 'Noto Sans TC';
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.MuiFormLabel-root.MuiInputLabel-root{
  font-family: 'Roboto';
}
.MuiPaper-root.MuiDialog-paper .MuiDialogActions-root{
  padding: 16px 24px;
}

.MuiTableRow-root.MuiTableRow-hover.clickableTable:hover{
  cursor: pointer;
  background-color: #FFF;
}

.MuiTableRow-root.MuiTableRow-hover.clickableTable:hover .MuiTableCell-root{
  font-weight: 700;
}

.apexcharts-yaxis-title text{
  transform: inherit;
  text-anchor: middle;
  dominant-baseline: hanging;
  writing-mode: tb;
  -webkit-writing-mode: tb;
  -ms-writing-mode: tb;
}

.MuiTable-root:not(.non-border-table) .MuiTableCell-root, .MuiTable-root:not(.non-border-table) .MuiTableRow-root:last-child td, .MuiTableRow-root:last-child th{
  border: 1px solid #e0e0e0!important;
}

.MuiList-root.MuiMenu-list{
  overflow: hidden;
}

main.MuiBox-root{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

main.MuiBox-root::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

#message_menu .MuiPaper-root{
  width: fit-content;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

#message_menu .MuiPaper-root::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.MuiDrawer-root .MuiPaper-root .MuiList-root{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.MuiDrawer-root .MuiPaper-root .MuiList-root::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.MuiButtonBase-root.MuiCheckbox-root{
  padding: 0;
}

@media (min-width: 1440px){
  .MuiContainer-root.mainContainer {
    max-width: 1440px;
  }
}

@media (min-width: 1200px){
  .MuiContainer-root.mainContainer {
    max-width: 1200px;
  }
}

@media (min-width: 425px){
  .MuiToolbar-root.fixToolbar{
    min-height: 80px;
    height: 80px;
  }
  .MuiBox-root .MuiSnackbar-root{
    top: 100px;
  }
}
@media (max-width: 1200px){
  .reportTable .MuiTableCell-root{
    padding: 3px 8px;
    font-size: 12px;
  }
  .reportTable .MuiButtonBase-root.MuiButton-root{
    min-width: 50px;
    padding: 0 3px;
  }
}
@media (max-width: 768px){
  .MuiFormControl-root.rwd-md, .MuiSelect-select.rwd-md{
    margin-top: 16px;
  }
}
@media (max-width: 700px){
  .MuiFormControl-root.rwd-smd, .MuiSelect-select.rwd-smd{
    margin-top: 16px;
  }
  .MuiGrid-root.MuiGrid-item{
    margin-left: 0;
    width: 100%;
  }
}
@media (max-width: 430px){
  .MuiFormControl-root.rwd-sm, .MuiSelect-select.rwd-sm{
    margin-top: 16px;
  }
}